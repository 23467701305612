import React from "react";

function SvgGalleryIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
        <path 
            d="M 2.06,14.30
            C 1.72,14.30 1.45,14.03 1.45,13.69
              1.45,13.52 1.51,13.37 1.63,13.25
              1.86,13.03 2.63,12.33 3.38,11.66
              3.92,11.18 4.47,10.68 4.59,10.56
              5.13,10.04 5.95,9.94 6.60,10.32
              6.60,10.32 7.81,11.02 7.81,11.02
              7.98,11.12 8.21,11.09 8.35,10.94
              8.35,10.94 10.14,9.08 10.14,9.08
              10.72,8.49 11.65,8.40 12.33,8.88
              12.33,8.88 16.41,11.53 16.41,11.53
              16.70,11.70 16.80,12.08 16.62,12.37
              16.45,12.66 16.07,12.75 15.78,12.58
              15.77,12.57 15.75,12.56 15.74,12.56
              15.74,12.56 11.63,9.89 11.63,9.89
              11.63,9.89 11.62,9.88 11.62,9.88
              11.43,9.75 11.19,9.77 11.03,9.93
              11.03,9.93 9.24,11.79 9.24,11.79
              8.70,12.35 7.85,12.47 7.19,12.08
              7.19,12.08 5.99,11.37 5.99,11.37
              5.81,11.27 5.59,11.30 5.45,11.44
              5.31,11.57 4.80,12.03 4.20,12.57
              3.54,13.17 2.70,13.92 2.50,14.12
              2.38,14.24 2.22,14.30 2.06,14.30
              2.06,14.30 2.06,14.30 2.06,14.30 Z
            M 6.37,8.47
            C 5.23,8.47 4.31,7.55 4.31,6.41
              4.31,5.27 5.23,4.35 6.37,4.35
              7.50,4.35 8.42,5.27 8.42,6.41
              8.42,7.55 7.50,8.47 6.37,8.47 Z
            M 6.37,5.58
            C 5.91,5.58 5.54,5.95 5.54,6.41
              5.54,6.87 5.91,7.24 6.37,7.24
              6.82,7.24 7.20,6.87 7.20,6.41
              7.20,5.95 6.82,5.58 6.37,5.58
              6.37,5.58 6.37,5.58 6.37,5.58 Z
            M 13.23,16.29
            C 13.23,16.29 4.77,16.29 4.77,16.29
              2.86,16.28 1.32,14.74 1.31,12.83
              1.31,12.83 1.31,4.37 1.31,4.37
              1.32,2.46 2.86,0.92 4.77,0.91
              4.77,0.91 13.23,0.91 13.23,0.91
              15.14,0.92 16.68,2.46 16.68,4.37
              16.68,4.37 16.68,12.83 16.68,12.83
              16.68,14.74 15.14,16.28 13.23,16.29
              13.23,16.29 13.23,16.29 13.23,16.29 Z
            M 4.77,2.14
            C 3.54,2.14 2.54,3.14 2.54,4.37
              2.54,4.37 2.54,12.83 2.54,12.83
              2.54,14.06 3.54,15.05 4.77,15.05
              4.77,15.05 13.23,15.05 13.23,15.05
              14.46,15.05 15.45,14.06 15.45,12.83
              15.45,12.83 15.45,4.37 15.45,4.37
              15.45,3.14 14.46,2.14 13.23,2.14
              13.23,2.14 4.77,2.14 4.77,2.14 Z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
  );
}

export default SvgGalleryIcon;
