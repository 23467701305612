import React from "react";

function SvgMembershipIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
        <path 
            d="M 2.57,3.43
            C 1.16,3.43 0.00,4.59 0.00,6.00
              0.00,6.00 0.00,18.00 0.00,18.00
              0.00,19.41 1.16,20.57 2.57,20.57
              2.57,20.57 21.43,20.57 21.43,20.57
              22.84,20.57 24.00,19.41 24.00,18.00
              24.00,18.00 24.00,6.00 24.00,6.00
              24.00,4.59 22.84,3.43 21.43,3.43
              21.43,3.43 2.57,3.43 2.57,3.43 Z
            M 2.57,5.14
            C 2.57,5.14 21.43,5.14 21.43,5.14
              21.92,5.14 22.29,5.51 22.29,6.00
              22.29,6.00 22.29,18.00 22.29,18.00
              22.29,18.49 21.92,18.86 21.43,18.86
              21.43,18.86 2.57,18.86 2.57,18.86
              2.09,18.86 1.72,18.49 1.72,18.00
              1.72,18.00 1.72,6.00 1.72,6.00
              1.72,5.51 2.09,5.14 2.57,5.14 Z
            M 7.72,6.86
            C 5.83,6.86 4.29,8.40 4.29,10.28
              4.29,11.24 4.70,12.10 5.33,12.72
              4.19,13.50 3.43,14.81 3.43,16.28
              3.43,16.28 5.15,16.28 5.15,16.28
              5.15,14.85 6.29,13.71 7.72,13.71
              9.15,13.71 10.29,14.85 10.29,16.28
              10.29,16.28 12.00,16.28 12.00,16.28
              12.00,14.81 11.24,13.50 10.10,12.72
              10.73,12.10 11.14,11.24 11.14,10.28
              11.14,8.40 9.60,6.86 7.72,6.86 Z
            M 13.72,7.72
            C 13.72,7.72 13.72,9.43 13.72,9.43
              13.72,9.43 20.57,9.43 20.57,9.43
              20.57,9.43 20.57,7.72 20.57,7.72
              20.57,7.72 13.72,7.72 13.72,7.72 Z
            M 7.72,8.57
            C 8.67,8.57 9.43,9.33 9.43,10.29
              9.43,11.25 8.67,12.00 7.72,12.00
              6.76,12.00 6.00,11.25 6.00,10.28
              6.00,9.32 6.75,8.57 7.72,8.57 Z
            M 13.72,11.14
            C 13.72,11.14 13.72,12.86 13.72,12.86
              13.72,12.86 20.57,12.86 20.57,12.86
              20.57,12.86 20.57,11.14 20.57,11.14
              20.57,11.14 13.72,11.14 13.72,11.14
              13.72,11.14 13.72,11.14 13.72,11.14 Z
            M 13.72,14.57
            C 13.72,14.57 13.72,16.29 13.72,16.29
              13.72,16.29 18.00,16.29 18.00,16.29
              18.00,16.29 18.00,14.57 18.00,14.57
              18.00,14.57 13.72,14.57 13.72,14.57 Z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
  );
}

export default SvgMembershipIcon;
