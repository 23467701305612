export default function token(state = null, action) {
  switch (action.type) {
    case 'FETCH_TOKEN_SUCCESS':
      return {
        ...state,
        ...action,
        error: null,
      };
    case 'INVALID_TOKEN': {
      console.log('INVALID TOKEN', action);
      return {
        error: action.error,
      };
    }
    default:
      return state;
  }
}
