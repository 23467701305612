import React from 'react';
import NavLink from './navlink';
import { withRouter } from 'react-router';
import Config from '../config';

class TopLevelNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: Config.userName,
      expanded: true,
      myNavItems: props.myNavItems,
      children: props.children,
      className: props.className,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // For now this is the decider on whether the menu is expanded.
  // You could also have a button to toggle expanded on/off instead.
  checkIsDash() {
    const { location } = this.props;
    const expanded = location.pathname.includes('/dashboard');
    if (expanded !== this.state.expanded) {
      this.setState({ expanded });
    }
  }

  handleLogout = () => {
    const username = this.state.username;
    // add params
    const options = {
      command: 'logout',
      user: username,
    };

    // construct URL
    let url = Config.url + '/portal/php/api.php';
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      })
      .join('&');
    url = url + '?' + bodyParams;

    // send request
    fetch(url, {
      method: 'post',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: bodyParams,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then(() => {
              window.location.reload();
            })
            .catch((error) => {
              // error
              console.log(error);
              return;
            });
        } else {
          console.log(response);
          return;
        }
      })
      .catch((error) => {
        // error
        console.log(error);
        return;
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expanded !== this.state.expanded && this.props.onExpand) {
      this.props.onExpand(this.state.expanded);
    }
  }

  get navState() {
    return this.state.expanded ? 'md:w-60' : 'md:w-24';
  }

  toggleMenu = () => {
    let expanded = !this.state.expanded;
    this.setState({ expanded: expanded });
  };

  render() {
    return (
      <div className="py-10 mainMenu">
        <div
          className={`flex flex-col gap-4 justify-center items-center transition-all ${this.navState}`}
        >
          <div
            className="list-none"
            onClick={() => {
              const l = window.location.origin;
              window.open(l);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              className=" w-12 object-contain mb-8"
              src={'./images/jensen-icon.png'}
              alt=""
              id="image-logo"
            />
            {this.state.expanded ? (
              <span className="text-lg text-light font-bold">
                Jensen
                <span className="text-blue-400">POS</span>
              </span>
            ) : (
              <div style={{ height: 25 }}></div>
            )}
          </div>
          <button onClick={this.toggleMenu}>
            <svg
              className={`hamburger ${this.state.expanded ? 'on' : ''}`}
              viewBox="0 0 120 120"
            >
              <g>
                <path
                  className="line top"
                  d="M35,35h50c14.1,0,50.6,13,20.5,53.5s-121.9,21.6-94.4-40.3S111.6,8.4,85,35L35,85"
                />
                <path
                  className="line bottom"
                  d="M35,85h50c14.1,0,50.6-13,20.5-53.5S-16.4,9.9,11.1,71.8S111.6,111.6,85,85L35,35"
                />
                <line className="line cross" x1="35" y1="60" x2="85" y2="60" />
              </g>
            </svg>
          </button>
          <nav className={`top-level-nav md:pr-0  w-full pl-6`}>
            <ul className="flex flex-col gap-4">
              {this.state.myNavItems.map((props) => (
                <NavLink
                  className="top-level-nav__item"
                  labelsActive={this.state.expanded}
                  {...props}
                  key={props.to}
                />
              ))}
            </ul>
          </nav>
          <div
            className={`navbar__user-profile mb-8 flex ${this.state.expanded &&
              'px-6 w-full'} justify-center items-center relative z-20`}
          >
            <div
              className={` flex gap-2 items-center ${this.state.expanded &&
                'bg-black w-full bg-opacity-20 rounded-3xl'}`}
            >
              <div
                className="navbar__user-profile__controls flex w-full"
                onClick={this.handleLogout}
                style={{ cursor: 'pointer' }}
              >
                <div className="navbar__user-profile__avatar w-max relative flex-shrink-0">
                  <i className="status-indicator border-2 border-quaternary w-5 h-5 rounded-full bg-green-400 absolute -top-1 -right-1" />
                  <img
                    className="w-14 h-14 object-contain rounded-full"
                    src="images/avatar.png"
                    alt="avatar"
                  />
                </div>

                {this.state.expanded && (
                  <span className="w-full text-light self-center p-4">Logout</span>
                )}
              </div>
            </div>
          </div>
          <div className={`navbar__logo ${this.state.expanded && 'px-6'}`}>
            <img className="w-14 h-14 flex-shrink-0" src="images/logo-mob.png" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TopLevelNav);
