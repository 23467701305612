import createAjaxReducer from './createAjaxReducer';

export default function(state = {}, action) {
  switch (action.type) {
    case `FETCH_PLUGINS_SUCCESS`: {
      const byGUID = {};
      const byType = {};
      for (let item of action.response.results) {
        byGUID[item.fdGUID] = item;
        if (!byType[item.fdCategory]) {
          byType[item.fdCategory] = [];
        }
        byType[item.fdCategory].push(item);
      }
      return {
        ...state,
        items: action.response.results,
        fields: action.response.fields,
        byGUID,
        byType,
        status: 'FETCHED',
      };
    }
    default: {
      const defaultReducer = createAjaxReducer('PLUGINS');
      return defaultReducer(state, action);
    }
  }
}
