import config from '../config';
import store from '../configureStore';
import { fetchData } from './ajaxActions';

store.subscribe(listener);
let token = '';
function listener() {
  const state = store.getState();
  if (state.token && state.token.token) {
    token = state.token.token;
  }
}

function ajaxError(name, error, type, opts) {
  return (dispatch) => {
    dispatch({
      error,
      options: opts,
      type: `${type}_${name}_ERROR`,
    });
    console.log('error: ', error);
    console.log('opts: ', opts);
    if (error.indexOf('Invalid Token') > -1)
      dispatch({
        type: `INVALID_TOKEN`,
        error: 'Session expired',
      });
  };
}
function ajaxSuccess(name, response, type, opts, data) {
  return (dispatch) => {
    dispatch({
      data,
      options: opts,
      response: response,
      type: `${type}_${name}_SUCCESS`,
    });
    if (response.tableupdates) {
      response.tableupdates.forEach((store) => {
        dispatch(fetchData(store));
      });
    }
  };
}
function ajaxRequest(name, options, type, blank) {
  return {
    options,
    type: `${type}_${name}_REQUEST`,
    blank,
  };
}

export function makeAjaxRequest(name, opts, type, data, blank, callback = null) {
  let bodyParams = Object.keys(opts)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(opts[key]);
    })
    .join('&');
  bodyParams += '&' + encodeURIComponent('token') + '=' + encodeURIComponent(token);

  return (dispatch) => {
    dispatch(ajaxRequest(name, opts, type, blank));
    let fetchURL =
      opts.domain === 'settings'
        ? config.url + '/portal/php/api.php'
        : config.url + '/apps/connector/api.php';
    return fetch(fetchURL, {
      method: 'post',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: bodyParams,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              if (json.success) {
                dispatch(ajaxSuccess(name, json, type, opts, data));
                if (callback && json.guid) {
                  callback(json.guid);
                } else if (callback) {
                  callback();
                }
              } else {
                const error = json.errors[0].error;
                dispatch(ajaxError(name, error, type, opts));
              }
            })
            .catch((error) => {
              console.log('JSON error in response', error); // eslint-disable-line no-console
            });
        } else {
          const error = new Error(response.statusText);
          error.response = response;

          dispatch(ajaxError(name, error, type, opts));
          throw error;
        }
      })
      .catch((error) => {
        console.log('request failed', error);
      }); // eslint-disable-line no-console
  };
}
