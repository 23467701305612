import * as React from 'react';

function SVGTrashCanIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 30"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
      <path
        d="M 23.61,2.64
        C 23.61,2.64 17.29,2.64 17.29,2.64
          17.29,2.64 17.29,0.38 17.29,0.38
          17.29,0.17 17.12,0.00 16.91,0.00
          16.91,0.00 7.10,0.00 7.10,0.00
          6.88,0.00 6.71,0.17 6.71,0.38
          6.71,0.38 6.71,2.64 6.71,2.64
          6.71,2.64 0.39,2.64 0.39,2.64
          0.17,2.64 0.00,2.81 0.00,3.02
          0.00,3.02 0.00,5.01 0.00,5.01
          0.00,5.22 0.17,5.39 0.39,5.39
          0.39,5.39 1.99,5.39 1.99,5.39
          1.99,5.39 4.09,26.66 4.09,26.66
          4.11,26.85 4.28,27.00 4.48,27.00
          4.48,27.00 19.52,27.00 19.52,27.00
          19.72,27.00 19.89,26.85 19.91,26.66
          19.91,26.66 22.01,5.39 22.01,5.39
          22.01,5.39 23.61,5.39 23.61,5.39
          23.83,5.39 24.00,5.22 24.00,5.01
          24.00,5.01 24.00,3.02 24.00,3.02
          24.00,2.81 23.83,2.64 23.61,2.64
          23.61,2.64 23.61,2.64 23.61,2.64 Z
        M 7.48,0.76
        C 7.48,0.76 16.52,0.76 16.52,0.76
          16.52,0.76 16.52,2.64 16.52,2.64
          16.52,2.64 7.48,2.64 7.48,2.64
          7.48,2.64 7.48,0.76 7.48,0.76 Z
        M 19.17,26.24
        C 19.17,26.24 4.83,26.24 4.83,26.24
          4.83,26.24 2.77,5.39 2.77,5.39
          2.77,5.39 21.23,5.39 21.23,5.39
          21.23,5.39 19.17,26.24 19.17,26.24
          19.17,26.24 19.17,26.24 19.17,26.24 Z
        M 23.23,4.63
        C 23.23,4.63 0.77,4.63 0.77,4.63
          0.77,4.63 0.77,3.40 0.77,3.40
          0.77,3.40 23.23,3.40 23.23,3.40
          23.23,3.40 23.23,4.63 23.23,4.63
          23.23,4.63 23.23,4.63 23.23,4.63 Z
        M 16.07,23.29
        C 16.08,23.29 16.12,23.49 16.07,23.66
          15.91,24.04 15.71,24.04 15.71,24.04
          15.71,24.04 16.79,10.13 16.79,10.13
          16.77,9.93 16.65,9.74 16.43,9.73
          16.22,9.71 16.03,9.87 16.02,10.08
          16.02,10.08 14.94,23.98 14.94,23.98
          14.92,24.19 15.08,24.37 15.29,24.39
          15.29,24.39 16.07,23.29 16.07,23.29 Z
        M 7.70,22.95
        C 7.71,23.14 7.88,23.30 8.08,23.31
          8.08,23.32 8.08,23.33 8.08,23.51
          8.06,23.88 7.88,23.86 7.67,23.86
          7.67,23.86 6.58,9.96 6.58,9.96
          6.57,9.75 6.38,9.60 6.17,9.61
          5.94,9.25 6.37,9.26 6.58,9.26
          6.58,9.26 7.67,23.16 7.67,23.16
          7.67,23.16 7.70,22.95 7.70,22.95 Z
        M 12.00,23.32
        C 12.21,23.32 12.39,23.15 12.39,22.94
          12.39,22.94 12.39,8.99 12.39,8.99
          12.39,8.78 12.21,8.61 12.00,8.61
          11.79,8.61 11.61,8.78 11.61,8.99
          11.61,8.99 11.61,22.94 11.61,22.94
          11.61,23.15 11.79,23.32 12.00,23.32 Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.2}
        />
      </g>
    </svg>
  );
}

export default SVGTrashCanIcon;
