import React from 'react';

function TextHeightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -2.21 20.414 20.414"
      className="icon"
      {...props}
    >
      <g
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(-2 -4)"
      >
        <path fill="#f4f6ff" d="M17 7l2-2 2 2"></path>
        <path d="M17 7l2-2 2 2"></path>
        <path d="M17 17l2 2 2-2" data-name="primary"></path>
        <path d="M13 7V5H3v2" data-name="primary"></path>
        <path d="M19 5v14M8 5v14m-2 0h4" data-name="primary"></path>
      </g>
    </svg>
  );
}

export default TextHeightIcon;
