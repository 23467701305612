import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import Login from './components/login';
// import registerServiceWorker from './registerServiceWorker';
import store from './configureStore';
import { Provider } from 'react-redux';
import moment from 'moment';

window.reduxStore = store;
window.moment = moment;

ReactDOM.render(
  <Provider store={store}>
    <Login />
  </Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
