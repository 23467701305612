import React from "react";

function SvgMenuboardIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
        <path 
            d="M 12.53,21.11
            C 12.53,20.85 12.31,20.63 12.05,20.63
              11.78,20.63 11.57,20.85 11.57,21.11
              11.57,21.38 11.78,21.59 12.05,21.59
              12.31,21.59 12.53,21.38 12.53,21.11 Z
            M 13.49,21.11
            C 13.49,21.91 12.84,22.55 12.05,22.55
              11.25,22.55 10.61,21.91 10.61,21.11
              10.61,20.32 11.25,19.67 12.05,19.67
              12.84,19.67 13.49,20.32 13.49,21.11
              13.49,21.11 13.49,21.11 13.49,21.11 Z
            M 19.46,23.03
            C 20.52,23.03 21.38,22.17 21.38,21.11
              21.38,21.11 21.38,2.88 21.38,2.88
              21.38,1.82 20.52,0.96 19.46,0.96
              19.46,0.96 4.56,0.96 4.56,0.96
              3.50,0.96 2.64,1.82 2.64,2.88
              2.64,2.88 2.64,21.11 2.64,21.11
              2.64,22.17 3.50,23.03 4.56,23.03
              4.56,23.03 19.46,23.03 19.46,23.03 Z
            M 19.46,23.99
            C 19.46,23.99 4.56,23.99 4.56,23.99
              2.97,23.99 1.68,22.70 1.68,21.11
              1.68,21.11 1.68,2.88 1.68,2.88
              1.68,1.29 2.97,-0.00 4.56,-0.00
              4.56,-0.00 19.46,-0.00 19.46,-0.00
              21.05,-0.00 22.34,1.29 22.34,2.88
              22.34,2.88 22.34,21.11 22.34,21.11
              22.34,22.70 21.05,23.99 19.46,23.99 Z
            M 18.01,18.23
            C 18.54,18.23 18.97,17.80 18.97,17.27
              18.97,17.27 18.97,4.82 18.97,4.82
              18.97,4.28 18.54,3.86 18.01,3.86
              18.01,3.86 6.01,3.86 6.01,3.86
              5.48,3.86 5.05,4.28 5.05,4.82
              5.05,4.82 5.05,17.27 5.05,17.27
              5.05,17.80 5.48,18.23 6.01,18.23
              6.01,18.23 18.01,18.23 18.01,18.23
              18.01,18.23 18.01,18.23 18.01,18.23 Z
            M 18.01,19.19
            C 18.01,19.19 6.01,19.19 6.01,19.19
              4.95,19.19 4.09,18.33 4.09,17.27
              4.09,17.27 4.09,4.82 4.09,4.82
              4.09,3.75 4.95,2.90 6.01,2.90
              6.01,2.90 18.01,2.90 18.01,2.90
              19.07,2.90 19.93,3.75 19.93,4.82
              19.93,4.82 19.93,17.27 19.93,17.27
              19.93,18.33 19.07,19.19 18.01,19.19
              18.01,19.19 18.01,19.19 18.01,19.19 Z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
  );
}

export default SvgMenuboardIcon;
