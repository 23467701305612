import React, { Component } from 'react';
import { DialogContainer } from 'react-md';
import Button from './button';
import App from '../App';
import { connect } from 'react-redux';
import { fetchToken } from '../actions/tokenactions';
import { fetchData } from '../actions/ajaxActions';
import Config, { ReactIsInDevelomentMode } from '../config';
import '../styles/loginStyles.scss';
import PropTypes from 'prop-types';
import { versionInfo } from '../version';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetting: false,
      username: Config.userName,
      password: Config.passWord,
      alertVisible: false,
      emailConfirmed: false,
      loginAlertVisible: false,
      logoutAlertVisible: false,
      serverCommit: '',
    };
    // this.handleSubmit();
    this.getServerInfo();
  }

  handleSubmit = () => {
    this.props.dispatch(
      fetchToken({
        command: 'login',
        user: this.state.username,
        pwd: this.state.password,
      }),
    );

    if (this.props.token && this.props.token.error && this.props.token.error > '') {
      this.setState({ error: this.props.token.error });
    }
  };

  handleForcedLogin = () => {
    this.hideLoginAlert();
    // Force login new session
    this.props.dispatch(
      fetchToken({
        command: 'login',
        user: this.state.username,
        pwd: this.state.password,
        forcedLogin: true,
      }),
    );
  };

  getServerInfo = () => {
    const options = {
      command: 'server.version',
    };

    // construct URL
    let url = Config.url + '/portal/php/api.php';
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      })
      .join('&');
    url = url + '?' + bodyParams;

    // send request
    fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              this.setState({
                serverCommit: json.commit,
              });
            })
            .catch((error) => {
              // error
              console.log(error);
              return;
            });
        } else {
          console.log(response);
          return;
        }
      })
      .catch((error) => {
        // error
        console.log(error);
        return;
      });
  };

  sendResetPassword = () => {
    // send email to API for email sending
    const email = this.state.username;

    // add params
    const options = {
      command: 'pass.send.email',
      email,
    };

    // construct URL
    let url = Config.url + '/portal/php/api.php';
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      })
      .join('&');
    url = url + '?' + bodyParams;

    // display message with email instructions
    this.setState({
      emailConfirmed: true,
    });

    // send request
    fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              // email sent
            })
            .catch((error) => {
              // error
              console.log(error);
              return;
            });
        } else {
          console.log(response);
          return;
        }
      })
      .catch((error) => {
        // error
        console.log(error);
        return;
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //prevent issue when parameters is reloaded causing a re-render even when nothing changes.
    if (nextProps.venueName && this.props.venueName !== nextProps.venueName) {
      document.title = 'JensenPOS - ' + nextProps.venueName;
      return true;
    }
    return !(
      JSON.stringify(nextProps.token) === JSON.stringify(this.props.token) &&
      JSON.stringify(nextState) === JSON.stringify(this.state)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.token &&
      this.props.token.token &&
      this.props.token.token > '' &&
      (!prevProps.token || this.props.token.token !== prevProps.token.token)
    ) {
      this.props.dispatch(fetchData('PARAMETERS'));
      this.props.dispatch(fetchData('USERTABLECOLUMNS'));
      if (this.props.token.alertMessage) {
        this.setState({ alertVisible: true });
      }
    }

    if (!prevState.loginAlertVisible && !this.state.loginAlertVisible) {
      this.setState({ loginAlertVisible: true });
    }

    if (
      this.props.token &&
      this.props.token.error === 'Session logged out' &&
      !prevState.logoutAlertVisible &&
      !this.state.logoutAlertVisible
    ) {
      this.setState({ logoutAlertVisible: true });
    }
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    window.history.replaceState(
      null,
      '',
      window.location.origin + window.location.pathname,
    );
    if (params.user && params.code && params.sess) {
      this.props.dispatch(
        fetchToken({
          command: 'login',
          user: params.user,
          pwd: params.code,
          sess: params.sess,
        }),
      );
    }
    this.setState({ error: '', loginAlertVisible: false, logoutAlertVisible: false });
  }

  hideAlert = () => {
    this.setState({ alertVisible: false });
  };

  hideLoginAlert = () => {
    this.setState({ error: '', loginAlertVisible: false });
  };

  hideLogoutAlert = () => {
    this.setState({ error: '', logoutAlertVisible: false });
  };

  toggleResetPassword = () => {
    this.setState({ isResetting: !this.state.isResetting, emailConfirmed: false });
  };

  render() {
    // let venueName = ''
    const { venueName } = this.props;
    // if(this.props.parameters && this.props.parameters['venuename']) venueName = this.props.parameters['venuename'].fdValue
    // if(true) {
    if (this.props.token && this.props.token.token && this.props.token.token > '') {
      return (
        <React.Fragment>
          <App
            applist={this.props.token.applist}
            username={this.state.username}
            user={this.props.token}
            dispatch={this.props.dispatch}
            venueName={venueName}
          />
          <DialogContainer
            id="alert"
            visible={this.state.alertVisible}
            title={this.props.token.alertTitle || 'System alert'}
            onHide={this.hideAlert}
            modal
            actions={[
              {
                onClick: this.hideAlert,
                primary: true,
                children: 'OK',
              },
            ]}
          >
            <div>{this.props.token.alertMessage}</div>
          </DialogContainer>
        </React.Fragment>
      );
    } else if (this.state.isResetting) {
      let errorMessage;
      if (this.props.token && this.props.token.error) {
        errorMessage = (
          <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
            {this.props.token.error}
          </div>
        );
      }

      return (
        <div className="h-screen">
          <div className="py-16">
            <div
              className="flex items-stretch p-10 mx-auto bg-white rounded-xl md-text-container"
              style={{
                flexFlow: 'column wrap',
              }}
            >
              <img
                src="./images/jensenpos-logo.png"
                style={{ margin: '0 auto' }}
                alt="JensenPOS"
              />
              {!this.state.emailConfirmed && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="text"
                    id="username"
                    className={`form-input`}
                    placeholder="Email"
                    required
                    defaultValue={this.state.username}
                    onChange={(e) => this.setState({ username: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.sendResetPassword();
                      }
                    }}
                    autoFocus
                  />
                  {this.state.emailConfirmed ? errorMessage : ''}
                  <div
                    style={{
                      marginTop: 20,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button onClick={this.sendResetPassword} className={`btn-login`}>
                      Reset Password
                    </Button>
                    <Button onClick={this.toggleResetPassword} className={`btn-login`}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {this.state.emailConfirmed && (
                <div
                  style={{
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: 'auto',
                  }}
                >
                  <h3
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '2rem',
                      textAlign: 'center',
                    }}
                  >
                    Password Reset Request Received
                  </h3>
                  <span style={{ textAlign: 'center' }}>
                    If the email address entered matches a user login, you will receive an
                    email with instructions on how to reset your password.
                  </span>
                  <div
                    style={{
                      marginTop: 20,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button onClick={this.toggleResetPassword} className={`btn-login`}>
                      OK
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      let errorMessage;
      if (this.props.token && this.props.token.error) {
        if (this.props.token.error === 'Session exists') {
          errorMessage = (
            <DialogContainer
              id="login-alert"
              visible={this.state.loginAlertVisible}
              title={'Login Confirmation'}
              onHide={this.hideLoginAlert}
              modal
              actions={[
                { primary: true, children: 'Yes', onClick: this.handleForcedLogin },
                { secondary: true, children: 'No', onClick: this.hideLoginAlert },
              ]}
            >
              <div>
                You're logged in on another computer. Do you want to log out from previous
                device and continue your login?
              </div>
            </DialogContainer>
          );
        } else if (this.props.token.error === 'Session logged out') {
          errorMessage = (
            <DialogContainer
              id="logout-alert"
              visible={this.state.logoutAlertVisible}
              title={'Remote Logout'}
              onHide={this.hideLogoutAlert}
              modal
              actions={[{ primary: true, children: 'OK', onClick: this.hideLogoutAlert }]}
            >
              <div>You have been logged out due to a login on another computer</div>
            </DialogContainer>
          );
        } else {
          errorMessage = (
            <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
              {this.props.token.error}
            </div>
          );
        }
      }
      const build =
        window.location.hostname === 'sandbox.jensenpos.com.au' ||
        ReactIsInDevelomentMode() ? (
          <div>
            <p>
              <a
                href={`https://bitbucket.org/jensenteam/jensensoftware/commits/${versionInfo.commit}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Portal: {versionInfo.version} {versionInfo.build}
              </a>
            </p>
            <p>
              <a
                href={`https://bitbucket.org/jensenteam/jensenpos-server/commits/${this.state.serverCommit}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Server: {this.state.serverCommit}
              </a>
            </p>
          </div>
        ) : (
          <p>
            {versionInfo.version} {versionInfo.build}
          </p>
        );

      return (
        <div className="h-screen">
          {/* <div className="p-12 text-center bg-primary">
                        <h2 className="text-4xl font-bold text-light">
                            Jensen<span className="text-blue-400">POS</span>
                        </h2>
                    </div> */}
          {/* <Toolbar className="bg-primary" title="JensenPOS" /> */}
          <div className="py-16">
            <div
              className="flex items-stretch p-10 mx-auto bg-white rounded-xl md-text-container"
              style={{ flexFlow: 'column wrap' }}
            >
              <img
                src="./images/jensenpos-logo.png"
                style={{ margin: '0 auto' }}
                alt="JensenPOS"
              />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Email"
                  required
                  className="form-input"
                  defaultValue={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (this.state.password > '') {
                        this.handleSubmit();
                      } else {
                        document.getElementById('password').focus();
                      }
                    }
                  }}
                />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  className="form-input"
                  defaultValue={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSubmit();
                    }
                  }}
                />
                {/* <TextField
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  helpText="Enter password"
                  required
                  className="md-cell md-cell--6"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e })}
                  onKeyDown={(e) => {
                    if (e.which === 13) {
                      this.handleSubmit();
                    }
                  }}
                /> */}
                {errorMessage}
                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button onClick={this.handleSubmit} className={`btn-login`}>
                    Login
                  </Button>
                  <p onClick={this.toggleResetPassword} className={`forgotPass`}>
                    Forgot Password?
                  </p>
                  {build}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.object,
  venueName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: state.token,
  venueName:
    state.parameters && state.parameters.byName && state.parameters.byName.venuename
      ? state.parameters.byName.venuename.fdValue
      : '',
});

export default connect(mapStateToProps)(Login);
