import config from '../config';

export function fetchTokenSuccess(response, opts) {
  if (response.token) {
    return (dispatch) => {
      dispatch({
        ...opts,
        ...response,
        type: 'FETCH_TOKEN_SUCCESS',
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        error: response.errors[0].error,
        type: 'INVALID_TOKEN',
      });
    };
  }
}

export function fetchTokenError(error) {
  return {
    error,
    type: 'FETCH_TOKEN_ERROR',
  };
}

export function fetchToken(opts) {
  let bodyParams = Object.keys(opts)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(opts[key]);
    })
    .join('&');

  return (dispatch) => {
    return fetch(config.url + '/portal/php/api.php', {
      method: 'post',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: bodyParams,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              dispatch(fetchTokenSuccess(json, opts));
            })
            .catch((error) => {
              console.log('JSON error in response', error); // eslint-disable-line no-console
            });
        } else {
          console.log(response); // eslint-disable-line no-console
          const error = new Error(response.statusText);
          error.response = response;
          dispatch(fetchTokenError(error));
          throw error;
        }
      })
      .catch((error) => {
        console.log('request failed', error);
      }); // eslint-disable-line no-console
  };
}
