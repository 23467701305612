import * as React from 'react';

function SVGClearFilterIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M 17.00,12.00
          C 18.33,12.00 19.60,12.53 20.54,13.46
            21.47,14.40 22.00,15.67 22.00,17.00
            22.00,19.76 19.76,22.00 17.00,22.00
            14.24,22.00 12.00,19.76 12.00,17.00
            12.00,14.24 14.24,12.00 17.00,12.00 Z
          M 17.00,13.00
          C 14.79,13.00 13.00,14.79 13.00,17.00
            13.00,19.21 14.79,21.00 17.00,21.00
            19.21,21.00 21.00,19.21 21.00,17.00
            21.00,14.79 19.21,13.00 17.00,13.00 Z
          M 18.71,14.58
          C 18.71,14.58 19.42,15.29 19.42,15.29
            19.42,15.29 17.71,17.00 17.71,17.00
            17.71,17.00 19.42,18.71 19.42,18.71
            19.42,18.71 18.71,19.42 18.71,19.42
            18.71,19.42 17.00,17.71 17.00,17.71
            17.00,17.71 15.29,19.42 15.29,19.42
            15.29,19.42 14.58,18.71 14.58,18.71
            14.58,18.71 16.29,17.00 16.29,17.00
            16.29,17.00 14.58,15.29 14.58,15.29
            14.58,15.29 15.29,14.58 15.29,14.58
            15.29,14.58 17.00,16.29 17.00,16.29
            17.00,16.29 18.71,14.58 18.71,14.58 Z
          M 18.00,4.00
          C 18.00,4.00 11.00,12.53 11.00,12.53
            11.00,12.53 11.00,18.25 11.00,18.25
            11.00,18.25 9.00,18.25 9.00,18.25
            9.00,18.25 9.00,12.53 9.00,12.53
            9.00,12.53 2.00,4.00 2.00,4.00
            2.00,4.00 18.00,4.00 18.00,4.00 Z
          M 13.77,6.00
          C 13.77,6.00 6.23,6.00 6.23,6.00
            6.23,6.00 10.00,10.60 10.00,10.60
            10.00,10.60 13.77,6.00 13.77,6.00 Z"
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth={0.4}
        />
      </g>
    </svg>
  );
}

export default SVGClearFilterIcon;
