import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { ListItem } from 'react-md';
import * as icons from '../images/icons';

const NavLink = ({
  label = '',
  to = '',
  exact = false,
  icon = null,
  labelsActive = true,
  className = '',
}) => {
  return (
    <Route path={to} exact={exact}>
      {({ match }) => {
        let leftIcon;

        if (icon) {
          const Icon = icons[icon];
          leftIcon = (
            <div
              className={`flex p-3 max-h-12 ${
                labelsActive && !!match ? 'text-white' : 'bg-primary text-white'
              } rounded-2xl`}
            >
              {Icon && <Icon className="h-6 w-6" />}
            </div>
          );
        }

        if (!!match) {
          className += ' text-white is-active';
        }

        return (
          <ListItem
            className={className}
            component={RouterLink}
            active={!!match}
            to={to}
            primaryText={labelsActive ? label : ''}
            leftIcon={leftIcon}
          >
            {!labelsActive && <div className="topnavlabel">{label}</div>}
          </ListItem>
        );
      }}
    </Route>
  );
};

export default NavLink;
