// Create a react component to be used as a base for creating accordions.
import React from 'react';

const Button = (props) => {
  const { primary, className, children, icon, secondary, ...p } = props;
  return (
    <button
      {...p}
      className={`btn btn--${primary ? 'primary' : 'secondary'} ${className || ''}`}
    >
      {children}
      {icon && icon}
    </button>
  );
};

export default Button;
