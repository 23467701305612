import createAjaxReducer from './createAjaxReducer';

export default function(
  state = {
    items: [],
    byGUID: {},
  },
  action,
) {
  switch (action.type) {
    case `INSERT_USERAPPS_SUCCESS`: {
      let idx = state.items.findIndex(function(entry) {
        if (entry.fdUserID === action.data.fdUserID) {
          return entry.fdAppName === action.data.fdAppName;
        }
        return false;
      });

      let newItems = [...state.items];
      if (idx === -1) {
        newItems.unshift({ ...action.data, fdGUID: action.response.guid });
        if (action.options.addLast)
          return {
            ...state,
            items: [
              ...state.items,
              {
                ...action.data,
                fdGUID: action.response.guid,
              },
            ],
          };
        if (action.response.fields) {
          let item = {
            ...action.data,
            fdGUID: action.response.guid,
          };
          for (let field in action.response.fields) {
            item[field] = action.response.fields[field];
          }
          return { ...state, items: [item, ...state.items] };
        }
        return {
          ...state,
          items: [{ ...action.data, fdGUID: action.response.guid }, ...state.items],
        };
      } else {
        if (action.response.fields) {
          let item = {
            ...newItems[idx],
            ...action.data,
            fdGUID: action.response.guid,
          };
          for (let field in action.response.fields) {
            item[field] = action.response.fields[field];
          }
          newItems[idx] = item;
        } else {
          newItems[idx] = {
            ...newItems[idx],
            ...action.data,
            fdGUID: action.response.guid,
          };
        }
      }
      const byGUID = {};
      for (let item of newItems) {
        byGUID[item.fdGUID] = item;
      }
      return { ...state, items: newItems, byGUID };
    }
    default: {
      const defaultReducer = createAjaxReducer('USERAPPS');
      return defaultReducer(state, action);
    }
  }
}
