import * as React from 'react';

function SVGEditIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
      <path
        d="M 21.12,2.71
        C 19.95,1.54 18.05,1.54 16.88,2.71
          16.88,2.71 15.20,4.39 15.20,4.39
          15.20,4.39 7.29,12.29 7.29,12.29
          7.16,12.42 7.07,12.58 7.03,12.76
          7.03,12.76 6.03,16.76 6.03,16.76
          5.94,17.10 6.04,17.46 6.29,17.71
          6.54,17.96 6.90,18.06 7.24,17.97
          7.24,17.97 11.24,16.97 11.24,16.97
          11.42,16.93 11.58,16.84 11.71,16.71
          11.71,16.71 19.56,8.86 19.56,8.86
          19.56,8.86 21.29,7.12 21.29,7.12
          22.46,5.95 22.46,4.05 21.29,2.88
          21.29,2.88 21.12,2.71 21.12,2.71 Z
        M 18.29,4.12
        C 18.68,3.73 19.32,3.73 19.71,4.12
          19.71,4.12 19.88,4.29 19.88,4.29
          20.27,4.68 20.27,5.32 19.88,5.71
          19.88,5.71 18.86,6.72 18.86,6.72
          18.86,6.72 17.31,5.11 17.31,5.11
          17.31,5.11 18.29,4.12 18.29,4.12 Z
        M 15.89,6.52
        C 15.89,6.52 17.45,8.14 17.45,8.14
          17.45,8.14 10.49,15.10 10.49,15.10
          10.49,15.10 8.37,15.63 8.37,15.63
          8.37,15.63 8.90,13.51 8.90,13.51
          8.90,13.51 15.89,6.52 15.89,6.52 Z
        M 4.00,8.00
        C 4.00,7.45 4.45,7.00 5.00,7.00
          5.00,7.00 10.00,7.00 10.00,7.00
          10.55,7.00 11.00,6.55 11.00,6.00
          11.00,5.45 10.55,5.00 10.00,5.00
          10.00,5.00 5.00,5.00 5.00,5.00
          3.34,5.00 2.00,6.34 2.00,8.00
          2.00,8.00 2.00,19.00 2.00,19.00
          2.00,20.66 3.34,22.00 5.00,22.00
          5.00,22.00 16.00,22.00 16.00,22.00
          17.66,22.00 19.00,20.66 19.00,19.00
          19.00,19.00 19.00,14.00 19.00,14.00
          19.00,13.45 18.55,13.00 18.00,13.00
          17.45,13.00 17.00,13.45 17.00,14.00
          17.00,14.00 17.00,19.00 17.00,19.00
          17.00,19.55 16.55,20.00 16.00,20.00
          16.00,20.00 5.00,20.00 5.00,20.00
          4.45,20.00 4.00,19.55 4.00,19.00
          4.00,19.00 4.00,8.00 4.00,8.00 Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.2}
        />
      </g>
    </svg>
  );
}

export default SVGEditIcon;
