import React from 'react';

function TextWidthIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="-0.21 0 20.414 20.414"
      className="icon"
      {...props}
    >
      <g
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(-2 -2)"
      >
        <path d="M19 21l2-2-2-2"></path>
        <path d="M5 17l-2 2 2 2" data-name="primary"></path>
        <path d="M7 5V3h10v2" data-name="primary"></path>
        <path d="M21 19H3m9-16v12m-2 0h4" data-name="primary"></path>
      </g>
    </svg>
  );
}

export default TextWidthIcon;
