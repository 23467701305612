import React from 'react';

function ListViewIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
        />
      </g>
    </svg>
  );
}

export default ListViewIcon;
